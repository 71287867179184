/* global logger */

import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { detect } from 'detect-browser';

import componentComposer from '../../core/componentComposer';
import {
    isClient,
    parseUrl,
    setCookie,
    dataLayer as DataLayer,
    currentLocale,
    miniGazelle,
    getUrl,
} from '../../helpers';
import GeoLocation from '../../components/modules/GeoLocation/GeoLocation';

const ComponentTree = (props = {}) => {
    const { page = {}, navigation = {}, location, httpStatus = 500, components = {}, labels = {}, loading } =
        props || {};

    const { modules = [], metadata = {}, requestRelated = {}, dataLayer = {} } = page || {};

    const { navigation: navigationData = {}, footer: footerData = {} } = navigation || {};

    const {
        seoTitle = '',
        seoDescription = '',
        seoIndexable,
        seoTags = '',
        type = '',
        logicalName = '',
        section = '',
        thumbnail = '',
    } = metadata || {};

    const { pathname = '' } = location;

    useEffect(() => {
        if (!basePath.includes('localhost')) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerHTML = `(function(a,b,c,d){ a='https://tags.tiqcdn.com/utag/sony-marketing/sony-pro/${tealiumEnv}/utag.js';
            b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
            a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a); })();`;

            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }
    }, []);

    /**
     * Set labels
     */

    miniGazelle.setLabels(labels);

    /**
     * product can be null type so guard against
     */

    const product = dataLayer?.product ? dataLayer?.product : {};
    const { retired } = product;

    const urlParser = new parseUrl();

    let localisationData = {
        language: dataLayer?.language ? dataLayer?.language : 'en',
        country: dataLayer?.country ? dataLayer?.country : 'EU',
        currentPath: urlParser.parse(pathname).raw.parsed,
    };

    localisationData.locale = `${localisationData.language}_${localisationData.country}`;

    /**
     * Set the re-useable locale data
     */

    currentLocale.set(localisationData.locale);

    /**
     * Set the browser cookie
     */

    setCookie({
        locale: localisationData.locale,
        currentPath: localisationData.currentPath,
    });

    /**
     * Load any optional extra styles or scripts depending on thw browser
     */

    const browser = detect() || {};

    const browserName = browser.name ? browser.name : 'ie';

    logger.info('src.containers.ComponentTree.js: Browser: %s', browserName);

    let scriptExtras = [];
    let styleExtras = [];

    switch (browserName) {
        case 'ie': {
            scriptExtras = [
                // {
                //     src: 'https://oss.maxcdn.com/html5shiv/3.7.3/html5shiv.min.js',
                //     type: 'text/javascript'
                // },
                // {
                //     src: 'https://oss.maxcdn.com/respond/1.4.2/respond.min.js',
                //     type: 'text/javascript'
                // }
            ];
            styleExtras = [
                {
                    rel: 'stylesheet',
                    type: 'text/css',
                    href: '/api/public/assets/styles/overrides/ngp-ie.css',
                },
            ];
            break;
        }

        case 'edge': {
            styleExtras = [
                {
                    rel: 'stylesheet',
                    type: 'text/css',
                    href: '/api/public/assets/styles/overrides/ngp-ie.css',
                },
            ];
            break;
        }

        default: {
            styleExtras = [];
            scriptExtras = [];
        }
    }

    /**
     * Fire data layer events
     */

    /**
     * Patch referrer
     */

    const referrer = '';

    if (isClient()) {
        DataLayer.prepareCustomEvent();
        DataLayer.update(metadata, modules, httpStatus, location, referrer, dataLayer, requestRelated);
    }

    /**
     * Compose components
     */

    const extraProps = {
        localisationData,
        labels,
        location,
        dataLayer,
        locale: localisationData.locale,
        browserName,
        metadata,
        setPurchaseData: () => {},
        purchaseData: {},
        loading,
    };

    logger.info('src.containers.ComponentTree.js: Composing components');

    const ComposedComponents = componentComposer({
        modules,
        extraProps,
        UiComponents: components,
    });

    const noIndex = (
        <Helmet
            meta={[
                {
                    name: 'robots',
                    content: 'noindex, nofollow',
                },
            ]}
        />
    );

    logger.info('src.containers.ComponentTree.js: Rendering...');

    const basePath = getUrl();
    const environments = ['preview', 'localhost', 'dev', 'stg'];
    const tealiumEnv = environments.some(env => basePath.includes(env)) ? 'qa' : 'prod';

    return (
        <Fragment>
            <Helmet
                htmlAttributes={{
                    lang: localisationData.language,
                }}
                title={seoTitle ? seoTitle : 'Sony Professional'}
                defaultTitle={'Sony Professional'}
                meta={[
                    {
                        name: 'description',
                        content: seoDescription,
                    },
                    {
                        name: 'keywords',
                        content: seoTags,
                    },
                    {
                        name: 'locale',
                        content: localisationData.locale,
                    },
                    {
                        name: 'logicalPage',
                        content: type ? type : 'default',
                    },
                    {
                        name: 'assetId',
                        content: logicalName ? logicalName : 'undefined-logical-name',
                    },
                    {
                        name: 'section',
                        content: section,
                    },
                    {
                        name: 'thumbnail',
                        content: thumbnail,
                    },
                    {
                        property: 'og:image',
                        content: thumbnail,
                    },
                    {
                        property: 'og:title',
                        content: seoTitle,
                    },
                    {
                        property: 'retired',
                        content: retired,
                    },
                ]}
                script={[
                    {
                        src: 'https://jwpsrv.com/library/6qcWCLNWEeS4Ig4AfQhyIQ.js',
                        type: 'text/javascript',
                    },
                    {
                        src: `https://tags.tiqcdn.com/utag/sony-marketing/sony-pro/${tealiumEnv}/utag.sync.js`,
                        type: 'text/javascript',
                    },
                    {
                        type: 'text/javascript',
                        innerHTML: 'var utag_data = { website_platform : "ngp" };',
                    },
                    ...scriptExtras,
                ]}
                link={styleExtras}
            />
            {isClient() && <GeoLocation />}
            {navigationData && <components.Navigation {...navigationData} />}
            {!seoIndexable === false ? noIndex : null}
            <main className="composed-components tw-font-sst-light">{ComposedComponents}</main>
            {/* footer */}
            {footerData && (
                <components.Footer
                    {...footerData}
                    locale={localisationData.locale}
                    countries={metadata && metadata.countries ? metadata.countries : null}
                />
            )}
        </Fragment>
    );
};

ComponentTree.propTypes = {
    httpStatus: PropTypes.number,
    /**
     * components
     */

    components: PropTypes.object.isRequired,

    /**
     * location
     */

    location: PropTypes.object.isRequired,

    /**
     * page
     */

    page: PropTypes.shape({
        metadata: PropTypes.object,
        modules: PropTypes.array,
        requestRelated: PropTypes.object,
        dataLayer: PropTypes.object,
        httpStatus: PropTypes.number,
    }).isRequired,

    /**
     * navigation
     */

    navigation: PropTypes.shape({
        navigation: PropTypes.object.isRequired,
        footer: PropTypes.object.isRequired,
        copyright: PropTypes.object.isRequired,
        country: PropTypes.object.isRequired,
    }).isRequired,

    /**
     * labels
     */

    labels: PropTypes.object,
};

export default ComponentTree;
