/**
 * @namespace lambda/events
 */

/**
 * @name payload
 * @description The local event payload for mocking an emulated lambda request
 *
 * @memberof lambda/events
 *
 *
 * @returns {object}
 */

module.exports = {
    path: '//home'
};
