import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import loading from './setLoading';
import page from './setPage';
import navigation from './setNavigation';
import labels from './setLabels';

export default combineReducers({
    routing: routerReducer,
    loading,
    page,
    navigation,
    labels
});
